import React from "react";

const Button = ({
    children,
    onClick,
    className = "",
    isDisabled = false,
    title = "",
}) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
        title={title}
        className={`${className} inline-flex items-center px-4 py-2 bg-outragous-orange border border-transparent rounded-3xl font-semibold justify-center text-md text-stone-50 tracking-widest hover:bg-red-500 focus:bg-red-500 active:bg-red-500 shadow-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-50 disabled:cursor-not-allowed`}
    >
        {children}
    </button>
);

export default Button;
